"use client";

import React, { createContext } from "react";
import { ExperienceObj } from "@/app/@types/experienceobj";
import { useExperiences } from "@/hooks/useExperiences";
import Loading from "@/components/Loading";

const ExperienceContext = createContext<ExperienceObj[]>([]);

/**
 * A React context provider component to store the YEX experiences.
 *
 * @remarks
 * This component fetches the YEX experiences stored in the Parse DB, and stores
 * them in a React state variable.
 * @param param0
 * @returns
 * The ExperienceContext Provider in JSX.
 */
export function ExperiencesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { experiencesData, isPending, isError, error } = useExperiences();

  if (isPending) {
    return <Loading />;
  }
  if (isError) {
    return <div>Error: {error?.message ?? "Unknown error"}</div>;
  }

  return (
    <ExperienceContext.Provider value={experiencesData ?? []}>
      {children}
    </ExperienceContext.Provider>
  );
}

export default ExperienceContext;
