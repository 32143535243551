import * as React from "react";
import Paper from "@mui/material/Paper";
import { Container, Typography } from "@mui/material";

/**
 * A React component that renders a loading page for the YEX application.
 *
 * @remarks
 * The Loading page component for the application used while waiting for
 * items to load from the network -
 *
 * @param props - React props not currently used.
 * @returns - The Loading React component in JSX.
 */
export default function Loading(props: any) {
  return (
    <Container maxWidth={false}>
      <Paper sx={{ height: "100vh", width: "100" }}>
        <Typography component="h1" variant="h5">
          Loading...
        </Typography>
      </Paper>
    </Container>
  );
}
