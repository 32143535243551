import { useQuery } from "@tanstack/react-query";
import { useParseContext } from "@/context/parseContext";
import { ExperienceObj } from "@/app/@types/experienceobj";

export const useExperiences = () => {
  const parse = useParseContext();
  const fetchExperiences = async () => {
    const fetchedExs: Parse.Object[] = await new parse.Query(
      "Experiences"
    ).findAll();
    return ExArrInit(fetchedExs);
  };
  function ExArrInit(experiences: Parse.Object[]): ExperienceObj[] {
    return experiences
      .filter((experience) => experience.get("active"))
      .map((experience) => ({
        id: experience.id,
        category: experience.get("category"),
        name: experience.get("name"),
        use: experience.get("use"),
        results: experience.get("results"),
        productWarnings: experience.get("productWarnings"),
        productUsageAdvisory: experience.get("productUsageAdvisory"),
        image: experience.get("image"),
      }));
  }

  const {
    data: experiencesData,
    isPending,
    isError,
    error,
  } = useQuery({
    queryKey: ["experiences"],
    queryFn: fetchExperiences,
  });

  return { experiencesData, isPending, isError, error };
};
