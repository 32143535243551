import { useQuery } from "@tanstack/react-query";
import { useParseContext } from "@/context/parseContext";
import { QuestionObj } from "@/app/@types/questionobj";

export const useQuestions = () => {
  const parse = useParseContext();
  const fetchQuestions = async () => {
    const fetchedQs: Parse.Object[] = await new parse.Query(
      "Questions"
    ).findAll();
    return QArrInit(fetchedQs);
  };
  const QArrInit = (questions: Parse.Object[]): QuestionObj[] => {
    return questions.map((question) => ({
      id: question.id,
      category: question.get("category"),
      question: question.get("question"),
    }));
  };

  const {
    data: questionsData,
    isPending,
    isError,
    error,
  } = useQuery({ queryKey: ["questions"], queryFn: fetchQuestions });

  return { questionsData, isPending, isError, error };
};
