"use client";

import { ParseProvider } from "./parseContext";
import { SurveyProvider } from "./surveyContext";
import { QuestionsProvider } from "./questionsContext";
import { SurveyDataProvider } from "./surveyDataContext";
import { UserProvider } from "./userContext";
import { ExperiencesProvider } from "./experienceContext";
import { QCategoriesProvider } from "./qcategoryContext";
import { SelectedExperProvider } from "./selectedExperContext";
import { CustomExperProvider } from "./customExperContext";
import { PastSessionsProvider } from "./pastSessionsContext";
import { SurveyReminderProvider } from "./surveyReminderContext";
import { QueryClientContextProvider } from "./reactQueryContext";
import { SubmittedExperProvider } from "./submittedExperContext";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ParseProvider>
      <UserProvider>
        <QueryClientContextProvider>
          <SurveyProvider>
            <QuestionsProvider>
              <SurveyDataProvider>
                <ExperiencesProvider>
                  <QCategoriesProvider>
                    <SubmittedExperProvider>
                      <SelectedExperProvider>
                        <CustomExperProvider>
                          <PastSessionsProvider>
                            <SurveyReminderProvider>
                              {children}
                            </SurveyReminderProvider>
                          </PastSessionsProvider>
                        </CustomExperProvider>
                      </SelectedExperProvider>
                    </SubmittedExperProvider>
                  </QCategoriesProvider>
                </ExperiencesProvider>
              </SurveyDataProvider>
            </QuestionsProvider>
          </SurveyProvider>
        </QueryClientContextProvider>
      </UserProvider>
    </ParseProvider>
  );
}
