import { useQuery } from "@tanstack/react-query";
import { useParseContext } from "@/context/parseContext";
const Parse = require("parse");

export const useQuestionCategories = () => {
  const parse = useParseContext();

  const fetchQuestionCategories = async () => {
    const fetchedQs: Parse.Object[] = await new parse.Query(
      "QuestionCategories"
    )
      .include("experienceOptions")
      .findAll();
    return fetchedQs;
  };

  const {
    data: questionCategoriesData,
    isPending: questionCategoriesIsPending,
    isError: questionCategoriesIsError,
    error: questionCategoriesError,
  } = useQuery({
    queryKey: ["questionCategories"],
    queryFn: fetchQuestionCategories,
  });

  const fetchRelation = async (relation: Parse.Relation) => {
    const fetchedRelation: Parse.Object[] = await relation.query().find();
    return fetchedRelation;
  };

  const fetchAllRelations = async (categories: Parse.Object[]) => {
    const allRelations = await Promise.all(
      categories.map((category) =>
        category.relation("experienceOptions").query().find()
      )
    );
    return allRelations;
  };

  const {
    data: allRelationsData,
    isPending: allRelationsIsPending,
    isError: allRelationsIsError,
    error: allRelationsError,
  } = useQuery({
    queryKey: ["allRelations", questionCategoriesData],
    queryFn: () => fetchAllRelations(questionCategoriesData!),
    enabled: !!questionCategoriesData,
  });

  console.log("allRelationsData", allRelationsData);
  return {
    questionCategoriesData,
    questionCategoriesIsPending,
    questionCategoriesIsError,
    questionCategoriesError,
    allRelationsData,
    allRelationsIsPending,
    allRelationsIsError,
    allRelationsError,
  };
};
