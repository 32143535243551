"use client";

import React, { createContext, useContext, useEffect, useState } from "react";
// import Parse from "parse/dist/parse";
const Parse = require("parse");
// import { useParseContext } from "@/context/parseContext";
import { useQuestionCategories } from "@/hooks/useQuestionCategories";
import { QCatObj } from "@/app/@types/qcategoryobj";
import Loading from "@/components/Loading";
import { all } from "node_modules/cypress/types/bluebird";

const QCategoryContext = createContext<QCatObj[]>([]);

/**
 * A React context provider to store the question categories.
 *
 * @remarks
 * This context provider component stores the different question categories used
 * to select the recommended experience from the end user survey response.
 *
 * @returns - The QCategoryContext provider in JSX.
 */
export function QCategoriesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    questionCategoriesData,
    questionCategoriesIsPending,
    questionCategoriesIsError,
    questionCategoriesError,
    allRelationsData,
    allRelationsIsPending,
    allRelationsIsError,
    allRelationsError,
  } = useQuestionCategories();

  if (questionCategoriesIsPending || allRelationsIsPending) {
    return <Loading />;
  }
  if (allRelationsIsError) {
    return <div>Error: {allRelationsError?.message ?? "Unknown error"}</div>;
  }
  if (questionCategoriesIsError) {
    return (
      <div>Error: {questionCategoriesError?.message ?? "Unknown error"}</div>
    );
  }

  const relationArrayToString = (relationData: Parse.Object[]) => {
    let relationDataString: string[] = [];
    for (var i = 0; i < relationData.length; i++) {
      relationDataString[i] = relationData[i].id;
    }
    return relationDataString;
  };
  let qCatArray: QCatObj[] = [];
  if (questionCategoriesData) {
    questionCategoriesData.forEach((categoryData, index) => {
      qCatArray.push({
        id: categoryData.id,
        category: categoryData.get("category"),
        experienceOptions: allRelationsData
          ? relationArrayToString(allRelationsData[index])
          : [],
      });
    });
  }

  return (
    <QCategoryContext.Provider value={qCatArray ?? []}>
      {children}
    </QCategoryContext.Provider>
  );
}

export default QCategoryContext;
