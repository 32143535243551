"use client";

import React, { createContext, useState } from "react";

const SubmittedExperContext = createContext<any>(false);

/**
 * A React context provider component to track if experience selection(s) have
 * been submitted via the recommendation form or custom selection form.
 *
 * @remarks
 * This context provider component sets and stores the boolean tracking if one of the
 * experience selection forms has been submitted.
 *
 * @param param0
 * @returns - The SubmittedExperContext provider component in JSX.
 */
export function SubmittedExperProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [submittedExper, setSubmittedExper] = useState(false);

  return (
    <SubmittedExperContext.Provider
      value={{ submittedExper, setSubmittedExper }}
    >
      {children}
    </SubmittedExperContext.Provider>
  );
}

export default SubmittedExperContext;
