"use client";

import React, { createContext, useState } from "react";
import { QuestionObj } from "@/app/@types/questionobj";
import { useQuestions } from "@/hooks/useQuestions";
import Loading from "@/components/Loading";

const QuestionsContext = createContext<QuestionObj[]>([]);

/**
 * A React context provider component to retreive and store the survey questions.
 *
 * @returns
 * This context provider component retreives and stores the survey questions from
 * the Parse DB.
 * @param param0
 * @returns - The QuestionsContext provider in JSX.
 */

export function QuestionsProvider({ children }: { children: React.ReactNode }) {
  // const [questions, setQuestions] = useState<QuestionObj[]>([]);

  const { questionsData, isPending, isError, error } = useQuestions();

  if (isPending) {
    return <Loading />;
  }
  if (isError) {
    return <div>Error: {error?.message ?? "Unknown error"}</div>;
  }

  // if (questionsData) {
  //   setQuestions(questionsData);
  // }
  return (
    <QuestionsContext.Provider value={questionsData ?? []}>
      {children}
    </QuestionsContext.Provider>
  );
}

export default QuestionsContext;
